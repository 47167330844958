import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import buyer from './buyer';
import common from './common';
import tasksReducer from './tasksReducer';
import taskViewReducer from './taskViewReducer';
import role from './role'
import popup from './popup'
import advanceSearchReducer  from './advanceSearchReducer';
import dashboardReducer from './dashboard';
import MdmReducer from './MdmReducer';
import UploadsReducer from './UploadsReducer'
export default combineReducers({
  data: app,
  auth: auth,
  buyer: buyer,
  common: common,
  tasksreducer: tasksReducer,
  taskViewReducer:taskViewReducer,
  role:role,
  popup:popup,
  advanceSearchReducer : advanceSearchReducer,
  dashboardReducer: dashboardReducer,
  MdmReducer:MdmReducer,
  UploadsReducer: UploadsReducer
});
