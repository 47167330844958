import { types } from "../actions/type";
const initialState = {
    loading: false,
    roleData:null,
    roleListType:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
  case types.ADD_NEW_ROLE:
    return{
      ...state,
      loading:false,
      roleListType:[ action.payload.data,...state.roleListType],
    }
    case types.GET_ROLE_LIST:
      return {
         ...state,
         roleListType: action.data,
          }
  case types.PROFILE_EDIT__DATA:
    return {
        ...state,
        profileData: action.data,
        }
    default:
      return state;
  }
};