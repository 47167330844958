import { types } from "../actions/type";
const initialState = {
    loading: false,
    // buyerData:[],
    reasonData:[],
    taskIdData:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.APPROVE_FORWARD_REJECT:
      return {
         ...state,
        //  buyerData: action.data,
          }
          case types.REJECT_REASON:
            return {
               ...state,
               reasonData: action.data,
                }
                case types.PO_CHANGE_TASK:
                  return {
                     ...state,
                     taskIdData: action.data,
                      }
    default:
      return state;
  }
};