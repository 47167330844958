import { types } from "../actions/type";
const initialState = {
  loading: false,
  buyerData: [],
  user_status: undefined,
  userId: undefined,
  newBuyerData: undefined,
  updateUserData: undefined,
  userDetails: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BUYER_LIST:
      return {
        ...state,
        buyerData: action.data,
      }

    case types.ADD_BUYER:
      return {
        ...state,
        loading: action.loading,
        newBuyerData: action.newBuyerData,
      }
    case types.ASSIGN_ROLE:
      return {
        ...state,
        loading: action.loading,
        updateRoleData: action.updateRoleData
      }

    case types.EDIT_USER:
      return {
        ...state,
        loading: action.loading,
        updateUserData: action.updateUserData
      }
    case types.CHANGE_USER_STATUS:
      return {
        ...state,
        loading: action.loading,
        user_status: action.user_status,
        userId: action.userId

      }
    case types.SHOW_LOADING: {
      return {
        ...state,
        loading: action.loading
      }
    }
    case types.LOGOUT: {
      state = initialState
    }
    case types.USER_DATA: {
			return {
				...state,
				userDetails: action.userDetails
			}
		}
    default:
      return state;
  }
};