import { types } from "../actions/type";
const initialState = {
  loading: false,
  postiingDateUrl: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_POSTING_DATE_URL:
      return {
        ...state,
        loading: false,
        postiingDateUrl: action.data
      }
    default:
      return state;
  }
};