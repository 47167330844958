import { types } from "../actions/type";
const initialState = {
    loading: false,
    accountPayableData: {},
    poMaterialData: {},
    totalLocation:[],
    stateTaskRecords:undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
  case types.DASHBOARD_COUNTS_ACCOUNTS_PAYABLE:
    return{
      ...state,
      loading:false,
      accountPayableData: action.data
    }
  case types.DASHBOARD_COUNTS_PO_MATERIAL:
      return{
        ...state,
        loading:false,
        poMaterialData: action.data
      }  
  case types.SET_TOTAL_LOCATION:
      return{
        ...state,
        loading:false,
        totalLocation: action.data
      }
  case types.SET_STATE_TASK_RECORDS:
      return{
        ...state,
        loading:false,
        stateTaskRecords: action.data
      }          
    default:
      return state;
  }
};