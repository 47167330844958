import { types } from "../actions/type";
const initialState = {
  toggleCollapsedNav: true,
  page: 0,
  logo: '',
  last_login_time: 'this is timing',
  visible: false,
  invDetails: {},
  headerHeading:'',
  taskHeading:'1',
  inboxType:'invoiceInbox',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_NAV:
      return {
        ...state,
        toggleCollapsedNav: !action.toggleCollapsedNav,
      }
      case types.HEADER_HEADING:
        return {
          ...state,
          headerHeading: action.headerHeading,
      }  
      case types.TASK_HEADING:
        return {
          ...state,
          taskHeading: action.taskHeading,
      } 
      case types.INBOX_TYPE:
        return {
          ...state,
          inboxType: action.inboxType,
      } 
    case types.PAGE_NO:
      return {
        ...state,
        page: action.page_no,
      }
    case types.LOGO:
      return {
        ...state,
        logo: action.logo,
      }
    case types.LAST_LOGIN_CHANGE:
      return {
        ...state,
        last_login_time: ''
      }
    case types.MODAL_ACTION:
      return {
        ...state,
        visible: action.payload
      }
    case types.READ_ONLY_INV_DETAILS:
      return {
        ...state,
        invDetails: action.invDetails
      }
    default:
      return state;
  }
};