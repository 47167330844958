module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NimbleS2P","short_name":"NimbleS2P","start_url":"/","background_color":"#551a8b","theme_color":"#551a8b","display":"standalone","icon":"src/images/logo/nimble_n.png","cache_busting_mode":"none","icons":[{"src":"src/images/logo/nimble_n.png","sizes":"192x192","type":"image/png"},{"src":"src/images/logo/nimble_n.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
