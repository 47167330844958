import { types } from "../actions/type";
const initialState = {
  loading: false,
  unAssignedState: {},
  miroPostiingFailed:{},
  duplicateMiro:{},

  assignSelfTask: null,
  load: false,
  error: null,
  unassignedTaskCardData: [],
  assignedTaskCardData: [],
  barCodeFailureTasks:{},
  taskName:''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UNASSIGNED_TASKS:
      localStorage.setItem('taskData', JSON.stringify(action.data.data));
      return {
        ...state,
        loading: false,
        unAssignedState: action.data
      }
    case types.MIRO_POSTING_FAILED:
      return {
        ...state,
        loading: false,
        miroPostiingFailed: action.data
      } 
    case types.BARCODE_FAILURE_TASKS:
        return {
          ...state,
          loading: false,
          barCodeFailureTasks: action.data
        } 
        case types.DUPLICATE_MIRO:
          return {
            ...state,
            loading: false,
            duplicateMiro: action.data
          }    
    case types.ASSIGNED_TASKS:
      return {
        ...state,
        loading: false,
        assignedState: action.data
      }

    case types.ASSIGN_SELF_SUCCESS:
      return {
        ...state,
        assignSelfTask: action.payload,
      }
    case types.ASSIGN_SELF_LOADING:
      return {
        ...state,
        load: action.payload,
      }
    case types.ASSIGN_SELF_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.ASSIGNED_CARD_DETAILS: {
      return {
        ...state,
        assignedTaskCardData: action.data
      }
    }
    case types.UNASSIGNED_CARD_DETAILS: {
      return {
        ...state,
        unassignedTaskCardData: action.data
      }
    }
    case types.TASK_TYPE_NAME:{
      return {
        ...state,
        taskName: action.value
        }
    }
    default:
      return state;
  }
};