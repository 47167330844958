import { types } from "../actions/type"
const initialState = {
  loading: false,
  invoiceHeaderData: null,
  grnsrnvalue: 0,
  grnSelected: [],
  grnselectedRowsData: [],
  navigationmenus: null,
  taskViewType: 1,
  nextPrevTask: {},
  TaxListOnSearch: [],
  viewRowDetails: {},
  poTableData: null,
  taskLifeCycle: [],
  GRNTableData: [],
  uploadedFiles: [],
  taskListFilterState: {},
  myTaskListFilterState: {},
  miroTaskListFilterState: {},
  duplicateTaskListFilterState: {},
  barCodeFailureTaskListFilterState: {},
  filesDescription: "",
  urlOnAssign: "",
  inboxTypeState: "invoiceInbox",
  dynamicTableValidation: [],
  filterParams: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INVOICE_HEADER_ITEM:
      return {
        ...state,
        loading: false,
        invoiceHeaderData: action.data,
      }
    case types.SET_NAVIGATION:
      return {
        ...state,
        loading: false,
        navigationmenus: action.data,
      }
    case types.GET_PO_TABLE:
      return {
        ...state,
        loading: false,
        poTableData: action.data,
      }
    case types.GET_GRN_TABLE:
      return {
        ...state,
        loading: false,
        GRNTableData: action.data,
      }
    case types.GET_ActionHistory_TABLE:
      return {
        ...state,
        loading: false,
        ActionHistoryTableData: action.data,
      }

    case types.TAX_LIST_OC_SEARCH:
      return {
        ...state,
        loading: false,
        TaxListOnSearch: action.data,
      }
    case types.SHOW_LOADING: {
      return {
        ...state,
        loading: action.loading,
      }
    }
    case types.GEN_SRN_VALUE: {
      return {
        ...state,
        grnsrnvalue: action.value,
        grnSelected: action.selectedRows,
        grnselectedRowsData: action.selectedRowsData,
      }
    }
    case types.TASK_VIEW_TYPE: {
      return {
        ...state,
        taskViewType: action.value,
      }
    }
    case types.NEXT_PREV_TASK: {
      return {
        ...state,
        nextPrevTask: action.value,
      }
    }
    case types.RESET_BEFORE_ENTER: {
      return {
        ...state,
        invoiceHeaderData: null,
        grnsrnvalue: 0,
        grnSelected: [],
        grnselectedRowsData: [],
        TaxListOnSearch: [],
        uploadedFiles: [],
      }
    }
    case types.ROW_DETAILS: {
      return {
        ...state,
        viewRowDetails: action.value,
      }
    }
    case types.GET_TASK_LIFE_CYCLE: {
      return {
        ...state,
        taskLifeCycle: action.data,
      }
    }
    case types.SET_UPLOADED_FILES_DATA: {
      return {
        ...state,
        uploadedFiles: action.value,
      }
    }
    case types.SET_UPLOADED_FILES_DESCRIPTION: {
      return {
        ...state,
        filesDescription: action.value,
      }
    }
    case types.TASK_LIST_FILTER_STATE: {
      return {
        ...state,
        taskListFilterState: action.value,
      }
    }
    case types.MY_TASK_LIST_FILTER_STATE: {
      return {
        ...state,
        myTaskListFilterState: action.value,
      }
    }
    case types.MIRO_TASK_LIST_FILTER_STATE: {
      return {
        ...state,
        miroTaskListFilterState: action.value,
      }
    }
    case types.DUP_TASK_LIST_FILTER_STATE: {
      return {
        ...state,
        duplicateTaskListFilterState: action.value,
      }
    }
    case types.BARCODE_TASK_LIST_FILTER_STATE: {
      return {
        ...state,
        barCodeFailureTaskListFilterState: action.value,
      }
    }
    case types.URL_ON_ASSIGN: {
      return {
        ...state,
        urlOnAssign: action.value,
      }
    }
    case types.INBOX_TYPE: {
      return {
        ...state,
        inboxTypeState: action.value,
      }
    }
    case types.DynamicTableValidation: {
      let tableValidations = state.dynamicTableValidation
      tableValidations.push(action.value)
      return {
        ...state,
        dynamicTableValidation: tableValidations,
      }
    }
    case types.ResetDynamicTableValidation: {
      let index = state.dynamicTableValidation.findIndex(
        ele => ele.key == action.value
      )
      let tValidations = state.dynamicTableValidation
      if (index != -1) {
        tValidations.splice(index, 1)
      }
      return {
        ...state,
        dynamicTableValidation: tValidations,
      }
    }
    case types.ResetCompleteDynamicTableValidation: {

      return {
        ...state,
        dynamicTableValidation: [],
      }
    }
    case types.FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.value,
      };
    case types.ACTIVE_TASK_TAB:
      return {
        ...state,
        activeTaskTab: action.value,
      };
    case types.ACTIVE_PARENT_TAB:
      return {
        ...state,
        activeParentTab: action.value,
      };
    case types.ACTIVE_SUB_MENU_TAB:
      return {
        ...state,
        activeSubMenuTab: action.value,
      };
    default:
      return state
  }
}
