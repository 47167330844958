import { types } from "../actions/type";
const initialState = {
  loading: false,
  searchTableData : [],
  searchDataTotal: 0,
  searchTLTableData: [],
  searchTLDataTotal: 0

};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADVANCED_SEARCH:
      return {
        ...state,
        loading: false,
        searchTableData: action.data
      }
    case types.ADVANCED_SEARCH_TL:
      return {
        ...state,
        loading: false,
        searchTLTableData: action.data
      }  
    case types.SHOW_LOADING: {
      return {
        ...state,
        loading: action.loading
      }
    }
    case types.ADVANCED_SEARCH_TOTAL: {
      return {
        ...state,
        searchDataTotal: action.data
      }
    }
    case types.ADVANCED_SEARCH_TOTAL_TL: {
      return {
        ...state,
        searchTLDataTotal: action.data
      }
    }
    default:
      return state;
  }
};